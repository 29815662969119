@import '/src/styles/variables.scss';

.breadcrumb {
  color: $contrastText;
  font-weight: 700;
  margin-bottom: 20px;

  li {
    a > p {
      color: $background-second;
      font-weight: 700;
      font-size: 14px;
    }

    &:last-child {
      a > p {
        cursor: initial;
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
}
