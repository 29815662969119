.title {
    text-align: center;
    position: relative;
    margin-bottom: 25px;
    font-size: 30px;

    &>h2 {
        display: inline-block;
        background: #f6f6f6;
        position: relative;
        padding: 0 20px;
    }

    &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        background: #acacac;
        width: 100%;
        height: 1px;
    }
}