.mySwiper1 {
  margin-bottom: 10px;
  height: auto;
}

.contentContainer {
  img {
    width: 100%;
    height: auto;
  }
}
