@import '/src/styles/variables.scss';

.content {
  & > p {
    margin-bottom: 10px;
  }
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 50px;
}

.images {
  display: flex;
  flex-direction: column;
  gap: 20px;
  & img {
    width: 100%;
    height: auto;
  }
}

@media screen and (max-width: ($sm + px)) {
  .container {
    flex-direction: column;
  }
}
