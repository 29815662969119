* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    min-height: 100vh;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    -webkit-text-size-adjust: 100%;
    margin: 0;
    background-color: #f6f6f6;
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

a {
    text-decoration: none;
    outline: none;
    // color: $color-blue;
    -webkit-tap-highlight-color: transparent;
}

button,
input,
select,
textarea {
    outline: none;
    // font-family: $font-primary;
}

img,
svg {
    vertical-align: top;
}

img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
    color: rgba(0, 0, 0, 0);
}

*::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: transparent;
}

* ::-webkit-scrollbar {
    width: 7px;
    height: 5px;
    position: absolute;
}

*::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #a5a5a5;
}

*::-webkit-scrollbar-thumb:hover {
    background-color: #7d7d7d;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

ul {
    list-style: none;
}

.container {
    width: 100%;
}