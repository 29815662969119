@import '/src/styles/variables.scss';

.root {
  position: relative;
  box-shadow: 3px 7px 12px #040404;
  border-radius: 4px;
  overflow: hidden;
  width: 100%;
  max-width: 350px;
  min-width: 300px;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #000000;
    opacity: .7;
    transition: .5s;
    cursor: pointer;
    z-index: 1;

  }

  &:hover::before {
    opacity: .1;
  }

  .btn {
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    bottom: 4%;
    z-index: 2;
  }

  .title {
    position: absolute;
    width: 100%;
    top: 50%;
    text-align: center;
    color: #FFF;
    z-index: 1;
    cursor: pointer;
    transform: translateY(-50%);
    font-weight: 600;
    font-size: 20px;
    text-transform: uppercase;
  };

}

// .imgContainer {
//   padding: 43%;
//   background: url('../../assets/mainPage/card.png') center no-repeat;
//   background-size: cover;
//   // box-shadow: inset 0 0 10px 10px #ffffff
// }