@import '/src/styles/variables.scss';

.cardContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 40px 20px;
  justify-content: center;
  align-items: center;
}

.addCard {
  width: 150px;
  height: 150px;
  border-radius: 10px;
  border: 5px dotted $contrastText;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 70px;
  cursor: pointer;
  transition: 0.5s;

  &:hover {
    border: 5px dotted $background-second;
  }
}

.cardBlock {
  position: relative;
}

.cardDelete {
  position: absolute;
  top: 0;
  right: 10px;
  font-size: 45px;
  font-weight: bold;
  color: red;
  z-index: 2;
  cursor: pointer;
  transform: rotateZ(45deg);
}
