@import '/src/styles/variables.scss';

.root {
  position: relative;

  .btn {
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
  }

}

// .imgContainer {
//   padding: 43%;
//   background: url('../../assets/mainPage/card.png') center no-repeat;
//   background-size: cover;
//   // box-shadow: inset 0 0 10px 10px #ffffff
// }