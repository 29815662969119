@import '/src/styles/variables.scss';

.contentContainer {
  display: flex;
  gap: 50px;
  align-items: center;
  justify-content: space-between;
}

.sliderContainer {
  width: 100%;
}

.zoomImg {
  max-width: 300px;
  width: 100%;
}

.sliderBlock {
  max-width: 400px;
  margin: auto;
}

.calcContainer {
  width: 100%;
}

.descriptionBlock {
  margin-top: 60px;

  ul li::before {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    background: $background-main;
    border-radius: 10px;
    position: absolute;
    left: 0;
    top: 15px;
  }
}

@media screen and (max-width: ($md + px)) {
  .sliderBlock {
    max-width: 335px;
  }

  .contentContainer {
    gap: 20px
  }
}

@media screen and (max-width: ($sm + px)) {
  .contentContainer {
    flex-direction: column;
  }

  .tableContainer {
    text-align: center;
  }

  .contentContainer {
    gap: 50px
  }
}
