@import '/src/styles/variables.scss';

.root {
  position: relative;
  padding: 0px;
  max-width: ($containerSize + px);
  margin: auto;
  padding-left: 24px;
    padding-right: 24px;
}

.image {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0px;
    bottom: -1px;
    left: 0px;
    right: 0px;
    background: linear-gradient(90deg, rgba(245, 247, 250, 0) 0%, rgba(245, 247, 250, 0) 45%, #F5F7FA 60%),
  }

  & img {
    display: block;
    max-width: 100%;
  }
}

.overlay {
  text-align: center;

  padding: 25px 10px 0;
  width: 47%;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.title {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
}

.linkBtn {
  padding-bottom: 50px;

  & button {
    text-transform: uppercase
  }
}

@media screen and (max-width: ($sm + px)) {
  .root {
    max-width: initial;
    padding-left: 0;
    padding-right: 0;
  }
  .image {
    &:before {
      top: 0;
      left: 0;
      right: 0;
      bottom: -1px;
      content: '';
      position: absolute;
      background: linear-gradient(180deg, rgba(245, 247, 250, 0) 0%, rgba(245, 247, 250, 0) 50%, #FFFFFF 100%);
    }

    & img {
      margin: 0 auto
    }
  }

  .overlay {
    position: initial;
    margin: 0;
    padding: 25px 0 0;
    background: linear-gradient(180deg, #FFFFFF 0%, #F5F7FA 100%);
    transform: none;
    width: 100%;
  }

  .linkBtn {
    padding-bottom: 0;
  }
}