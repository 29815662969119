@import '/src/styles/variables.scss';

.list {
  line-height: 1.5em;
  margin: 5px 0 15px;
  padding: 0;
  li {
    list-style: none;
    position: relative;
    padding: 0 0 0 20px;
    line-height: 2;
  }

  li::before {
    content: '';
    position: absolute;
    left: 0;
    top: 15px;
    width: 11px;
    display: block;
    border-top: 2px solid $contrastText;
  }
}

.content {
  display: flex;
  align-items: center;
  margin-top: 20px;
  justify-content: space-between;

  & > div {
    & > img {
      max-width: 450px;
      width: 100%;
      height: auto;
    }
  }
}

@media screen and (max-width: ($sm + px)) {
  .content {
    flex-direction: column;
  }
}