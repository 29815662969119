@import '/src/styles/variables.scss';

.listContainer{
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 20px 50px;
}

.list {
  line-height: 1.5em;
  margin: 5px 0 15px;
  padding: 0;
  li {
    list-style: none;
    position: relative;
    padding: 0 0 0 20px;
    line-height: 2;
  }

  li::before {
    content: '';
    position: absolute;
    left: 0;
    top: 15px;
    width: 11px;
    display: block;
    border-top: 2px solid $contrastText;
  }
}
.content {
  &>p {
    margin-bottom: 10px;
  }
}