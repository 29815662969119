@import '/src/styles/variables.scss';

.arrowBtn {
    cursor: pointer;
    font-size: 20px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;

    &>svg {
        font-size: 50px;
        color: $contrastText;
    }

    &:hover {
        border: none
    }
}

.arrowBtnRight {
    right: 0px
}

.arrowBtnLeft {
    left: 10px
}
