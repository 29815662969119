@import '/src/styles/variables.scss';

.content {
  background-color: $background-main;
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contentItems {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.link {
  & a {
    transition: .2s;
    color: $contentBackground;

    &:hover {
      color: $contrastText;
    }
  }
}

.nav {
  height: 60px;
  background: #FFFFFF;
  display: flex;
  align-items: center;
}

.iconContainer {
  display: none;
}

.listItem {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px 20px;

  &>p {
    cursor: pointer;
    text-align: center;
  }
}

.item {
  position: relative;
  overflow: hidden;
  transition: .2s;

  &::before {
    content: '';
    transition: .2s;
    position: absolute;
    width: 100%;
    height: 2px;
    background: $background-main;
    bottom: 0;
    left: -100%;
  }

  &:hover {
    &::before {
      left: 0;
    }
  }
}

.downloadPrice {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  gap: 10px;

  &>img {
    width: 20px;
  }
}

.sideMenu {
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.sideBlock {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.logoMobile {
  display: none;
}

@media screen and (max-width: ($md + px)) {
  .iconContainer {
    display: flex;
    justify-content: center;

    svg {
      font-size: 36px;
    }
  }

  .listItem {
    display: none;
  }

  .logoMobile {
    display: block;
    svg {
      width: 90px;
      height: auto;
    }
  }

  .navBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media screen and (max-width: ($sm + px)) {
  .content {
    height: auto;
    padding: 10px;
  }

  .addressBlock {
    display: flex;
    justify-content: center;
  }

  .contentItems {
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px
  }
}