.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  background: #FFFFFF;
  border: 2px solid #000;
  box-shadow: 24;
  padding: 20px;
  overflow-y: scroll;
  max-height: 92vh;
}
