$background-main: #00a0ff;
$background-second: #1596c0;
$contrastText: #eca10c;
$contentBackground: #FFFFFF;

$textOnBackground: #FFFFFF;

$xs: 426;
$sm: 769;
$md: 1110;
$lg: 1201;
$xl: 1441;

$containerSize: 1350;

:export {
    xs: $xs;
    sm: $sm;
    md: $md;
    lg: $lg;
    xl: $xl;
    containerSize: $containerSize;
    backgroundMain: $background-main;
    backgroundSecond: $background-second;
}