@import '/src/styles/variables.scss';

.root {
  // background-color: $background-main;
  background: url('../../assets/backgroundFooter.avif') center no-repeat;
  background-size: cover;
  position: relative;

  &::before {
    position: absolute;
    display: block;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $background-main;
    opacity: 0.9;
    z-index: 0;
  }
}

.content {
  position: relative;
  display: flex;
  padding: 2rem 0;
  justify-content: space-around;
  align-items: center;
}

.contactInfo {
  display: flex;
  padding: 13px 0;
  flex-direction: column;
  justify-content: space-around;

  & a {
    transition: .2s;
    color: $contentBackground;

    &:hover {
      color: $contrastText;
    }
  }
}

.contactItems {
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  gap: 18px
}

.imgContent {
  svg {
    width: 200px;
    height: auto;
  }
}

@media screen and (max-width: ($sm + px)) {
  .imgContent {
    display: none;
  }
}