@import '/src/styles/variables.scss';

.calcBlock {
    display: flex;
    flex-direction: column;
    gap: 25px 20px;
    align-items: center;
    width: 100%;
    max-width: 520px;
    margin: auto;
    background: #FFF;
    padding: 40px 20px;
    border-radius: 8px;
    box-shadow: 1px 1px 8px $background-main;
}

.productType {
    color: $textOnBackground;
    background: $background-main;
    font-size: 34px;
    font-weight: 700;
    text-align: center;
    border-radius: 5px;
    display: inline-block;
    padding: 0 30px;
    max-width: 280px;
}

.inputSize {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.resultBlock {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    width: 100%;
    align-items: center;
}
