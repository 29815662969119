@import '/src/styles/variables.scss';

.block {
  margin-top: 60px;
}

.slider {
  margin-top: 60px;
}

@media screen and (max-width: ($sm + px)) {
  .block {
    margin-top: 30px;
  }
}