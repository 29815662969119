@import '/src/styles/variables.scss';

.title {
    text-align: center;
    position: relative;
    margin-bottom: 25px;
    
    &>h2 {
        font-size: 30px;
        display: inline-block;
        background: #f6f6f6;
        position: relative;
        padding: 0 20px;
        text-transform: none !important;
    }

    &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        background: #acacac;
        width: 100%;
        height: 1px;
    }
}

.subTitle {
    max-width: 650px;
    margin: auto;
    text-align: center;
}

@media screen and (max-width: ($md + px)) {
    .title {
        &>h2 {
            padding: 0 15px;
        }
    }
}

@media screen and (max-width: ($sm + px)) {
    .title {
        &>h2 {
            font-size: 24px;
        }
    }
}

@media screen and (max-width: ($xs + px)) {
    .title {
        &>h2 {
            font-size: 20px;
        }
    }
}