@import '/src/styles/variables.scss';

.root {
  padding: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.text {
  width: 60%;
  padding: 24px 16px;
  font-size: 12px;
}

.title {
  padding-bottom: 0.5rem;
}

.description {
  padding-bottom: 1.5rem;
}

.imageBlock {
  width: 40%;
}

@media screen and (max-width: ($sm + px)) {
  .root {
    flex-direction: column;
  }

  .title,
  .description {
    text-align: center;
  }

  .text {
    font-size: 14px;
    padding: 0;
  }

  .text,
  .imageBlock {
    width: 100%;
  }
}
